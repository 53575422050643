import React from "react";
//ContactInfo Area
const ContactInfo = () => {
  return (
    <>
      <div>
        <div>
          <h5>Our Address</h5>
          <p>AKEVLER MAH. 1069 SOK. 9E/58. Esenyurt/Istanbul 34513. TURKEY</p>
        </div>
        <div>
          <h5>Call us on</h5>
          <p>Office: +90 531 947 27 31</p>
          <p>Customer Care: +90 531 947 27 31</p>
        </div>
        <div>
          <h5>Mail Us at</h5>
          <p>hello@freshtradingltd.com</p>
          <p>support@freshtradingltd.com</p>
        </div>
      </div>
    </>
  );
};

export default ContactInfo;

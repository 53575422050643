import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import ConsultingServices from "../components/sections/ConsultingServices";
import Cta from "../components/sections/Cta";
import LogisticServices from "../components/sections/Logistics_Services";
import AboutFounder from "../components/sections/About";
import { Grid } from "@material-ui/core";
const Home = () => {
  return (
    <>
      <Hero className="illustration-section-01" />
      <AboutFounder />
      <FeaturesTiles />
      <Grid container spacing={1}>
        <div style={{ width: "100%" }}>
          <LogisticServices />
        </div>
      </Grid>
      <FeaturesSplit
        invertMobile
        imageFill
        className="illustration-section-02"
      />
      <ConsultingServices />
      <Cta split />
    </>
  );
};

export default Home;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: 400,
    marginTop: 50,
  },
  formInput: {
    backgroundColor: "black",
  },
  button: {
    color: "#fff",
    backgroundColor: "#85bc57",
    height: 50,
    borderRadius: 4,
    border: "1px solid #85bc57",
    width: 400,
  },
});

const propTypes = {
  tag: PropTypes.elementType,
  color: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.bool,
  wide: PropTypes.bool,
  wideMobile: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  tag: "button",
  color: "",
  size: "",
  loading: false,
  wide: false,
  wideMobile: false,
  disabled: false,
};

const Button = ({
  className,
  tag,
  color,
  size,
  loading,
  wide,
  wideMobile,
  disabled,
  val,
  ...props
}) => {
  const styles = useStyles();

  const Component = tag;
  return (
    <Component {...props} className={styles.button} disabled={disabled}>
      {val}
    </Component>
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;

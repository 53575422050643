import React from "react";
// import sections
import Privacy from "../components/Privacy";

const Home = () => {
  return (
    <>
      <Privacy />
    </>
  );
};

export default Home;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {},
  heading: {
    fontSize: 45,
  },
  text: {
    fontSize: 20,
    fontWeight: 400,
  },
});

const propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
};

const Button = ({ heading, text, ...props }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <h2 className={styles.heading}>{heading}</h2>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

Button.propTypes = propTypes;

export default Button;

import React from "react";
// import sections
import Contact from "../components/Contact";

const Home = () => {
  return (
    <>
      <Contact />
    </>
  );
};

export default Home;

import React from "react";
// Import LogisticsCard
import LogisticsCard from "./LogisticsCard";
// Import LogisticsData
import { LogisticsData } from "./Logistics_Data";
// import Section Heading
import { Grid } from "@material-ui/core";
import SectionHeader from "./SectionHeader";

const LogisticsService = () => {
  const sectionHeader = {
    title: "Logistic Services We Offer",
    paragraph:
      "Fresh trading is an internationally recognized supply chain solutions provider headquartered in Istanbul. Our professional team is ready to serve our customers in all areas of the supply chain and logistics industry.",
  };

  return (
    <>
      <section id="logistics_area" style={{ backgroundColor: "#fff" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="logistics_wrappers">
                <SectionHeader
                  data={sectionHeader}
                  className="center-content"
                />
                <Grid container spacing={3}>
                  {LogisticsData[0].map((data, index) => (
                    <Grid item xs={12} sm={12} lg={4} xl={4}>
                      <LogisticsCard
                        img={data.img}
                        heading={data.heading}
                        para={data.para}
                        key={index}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LogisticsService;

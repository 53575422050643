import img1 from "../../../assets/images/logistic/two.jpg";
import img2 from "../../../assets/images/logistic/truck-delivery.png";
import img3 from "../../../assets/images/logistic/one.jpg";
import img4 from "../../../assets/images/logistic/custom.png";
import img6 from "../../../assets/images/logistic/pack.png";
import img7 from "../../../assets/images/logistic/shipment.png";
import img5 from "../../../assets/images/logistic/ship.png";

export const LogisticsData = [
  [
    {
      img: img3,
      heading: "Rail Freight",
      para: "Due to their structure, trains have the ability to carry heavy loads easily. Best for shorter distances.",
    },
    {
      img: img1,
      heading: "Airways Transportation",
      para: "The most important advantage of air transport is undoubtedly the faster option.",
    },
    {
      img: img5,
      heading: "Maritime Transportation",
      para: "We enable the transportation of the desired amount of cargo with large cargo ships.",
    },
    {
      img: img7,
      heading: "Exports to all Countries",
      para: "We handle the exports of goods from Turkey to anyone anywhere in the World. We control the supply chain.",
    },
    {
      img: img6,
      heading: "Packing and Packaging",
      para: "We package goods in accordance with the customer's needs, and ensure the safety of these goods before shipment.",
    },
    {
      img: img2,
      heading: "Custom Clearance",
      para: "Our professional team surpervises the workflow of the export process to ensure that no financial losses occur.",
    },
    {
      img: img4,
      heading: "Feasibility Study",
      para: "We study the economic feasibility of our customers' projects regarding the international trade's rules.",
    },
  ],
];

import React from "react";
// import sections
import ForeignTrade from "../components/ForeignTrade";

const Home = () => {
  return (
    <>
      <ForeignTrade />
    </>
  );
};

export default Home;

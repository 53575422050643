import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import {
  MdAgriculture,
  MdMedicalServices,
  MdCastForEducation,
} from "react-icons/md";
import {
  GiSonicShoes,
  GiChemicalDrop,
  GiFoodTruck,
  GiClothes,
  GiBookshelf,
  GiHuntingHorn,
} from "react-icons/gi";
import { IoIosConstruct } from "react-icons/io";
import { FaTractor, FaChair, FaBabyCarriage } from "react-icons/fa";
import { BiHealth } from "react-icons/bi";
import { SlEnergy } from "react-icons/sl";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  imgBox: {
    backgroundColor: "#85bc57",
    borderRadius: "50%",
    margin: "auto",
    width: "19%",
    height: 60,
    display: "flex",
    alignItems: "center",
  },
});

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Browse Products We Deal In",
    paragraph:
      "At Fresh trading, we focus in a variety of products; Agriculture and vetinary materials, Renewable energy and more. We specialize in the exports of the following products from turkey:",
  };

  const styles = useStyles();

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className={styles.imgBox}>
                    <MdAgriculture size={35} style={{ color: "#e9f2ff" }} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Agriculture and Veterinary Materials
                  </h4>
                  <p className="m-0 text-sm">
                    Culti parker, Cultivator, Shanks, Planters, Seed drills,
                    Trowels, Sub soiler, Harrows, Ploughs, Spray heads,
                    Sprinkler system, Hydroponics, Foetal extractor, Irrigator,
                    Cotton picker, Farm truck Grain dryer, Harvestor, etc.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className={styles.imgBox}>
                    <GiSonicShoes size={35} style={{ color: "#e9f2ff" }} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Shoes and Leather Products</h4>
                  <p className="m-0 text-sm">
                    Clothing, Hats, Footwear, Gloves, Wristwatch, Straps,
                    Handbags, Purses, Toys, Containing, Leather, Furniture, Soft
                    furnishings equine equipment, such as saddles and bridles,
                    etc.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className={styles.imgBox}>
                    <GiChemicalDrop size={35} style={{ color: "#e9f2ff" }} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Chemical Products</h4>
                  <p className="m-0 text-sm">
                    Raw materials for synthetic fibers, Adhesives, Plasticizers,
                    Rubber chemicals, Polymer additives Plastics, Modifiers for
                    films, Paper processing resins, Fiber processing resins etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className={styles.imgBox}>
                    <GiFoodTruck size={35} style={{ color: "#e9f2ff" }} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Canned food of all kinds</h4>
                  <p className="m-0 text-sm">
                    Nuts and dried fruits, Fish and see food, Milk and diary
                    Items, Gourmet, Chocolate and cocoa products, cConfectionery
                    & snacks, Sport supplements etc.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className={styles.imgBox}>
                    <IoIosConstruct size={35} style={{ color: "#e9f2ff" }} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Construction and Building Supplies
                  </h4>
                  <p className="m-0 text-sm">
                    Construction materials, Interior cladding, Kitchen and
                    bathroom accessories, Roads and garden equipments, Metals,
                    Polymer, Ceramics.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className={styles.imgBox}>
                    <GiClothes size={35} style={{ color: "#e9f2ff" }} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Clothes Fabrics and Textile</h4>
                  <p className="m-0 text-sm">
                    Lace materials, Chiffon, Silk, Wool, Linen, Cotton,
                    Synthetic fibers such as rayon, nylon, and polyesters, and
                    Some inorganic fibers such as cloth of gold, glass fiber,
                    and asbestos cloth.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className={styles.imgBox}>
                    <MdMedicalServices size={35} style={{ color: "#e9f2ff" }} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Medical Materials and Devices</h4>
                  <p className="m-0 text-sm">
                    Surgical equipments, Medical Bone Drill Bit , Orthopedic
                    Instrument Set , Orthopedic Implants , Spare Medical
                    Instrument , Orthopedic Plates, Surgical power device ,
                    Endoscopic system , Medical stapler etc.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className={styles.imgBox}>
                    <FaTractor size={35} style={{ color: "#e9f2ff" }} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Industrial Machinery and Devices
                  </h4>
                  <p className="m-0 text-sm">
                    Welders, Extruders, Forklifts, Chainsaws, Air compressors,
                    Clamps, Free standing shelving, Software, ladders,
                    Wheelbarrows, Pulleys etc.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className={styles.imgBox}>
                    <FaChair size={35} style={{ color: "#e9f2ff" }} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Furniture and Fixtures</h4>
                  <p className="m-0 text-sm">
                    Office sets, Office decks and work Stations, Office
                    wardrobes and storage units, Waiting room surfers, Office
                    accessories, Home materials and equipment, Hotel furniture
                    equipments, Sports club furniture etc.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className={styles.imgBox}>
                    <GiBookshelf size={35} style={{ color: "#e9f2ff" }} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">School Supplies and Stationery</h4>
                  <p className="m-0 text-sm">
                    Stationery equipment, Stationery, School supplies, School
                    stationery materials, Backpacks, Schoolbags, Books, Teaching
                    materials, pinboards, corkboards, catalogue, brochure,
                    Signboards, Awnings, Tarpaulin, Cork boards, Stands, Stand
                    Products, Copying, Office supplies etc.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className={styles.imgBox}>
                    <FaBabyCarriage size={35} style={{ color: "#e9f2ff" }} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Baby Products</h4>
                  <p className="m-0 text-sm">
                    Baby accessories, Baby apparel, Baby feeding, Baby
                    Furniture, Baby health care, Baby clothes, Materials and
                    neccessities for pregnant women, Baby toys, Diaper bags,
                    Accessories etc.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className={styles.imgBox}>
                    <GiHuntingHorn size={35} style={{ color: "#e9f2ff" }} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Wild Hunting Products</h4>
                  <p className="m-0 text-sm">
                    Base and mid layers like Pel0ton 118 and StrongFleece Hybrid
                    210, Pants like tylus hybrid pant, Outer layers like Kenai
                    vest and guide jackets, Packs, Accessories etc.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className={styles.imgBox}>
                    <BiHealth size={35} style={{ color: "#e9f2ff" }} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Health Tourism</h4>
                  <p className="m-0 text-sm">
                    Orthopedics, Dental or Dentistry, Cosmetic/Plastic surgery,
                    Cardiology, Fertility treatment, Eye surgery, Ears, nose,
                    and throat, Organ transplants etc.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className={styles.imgBox}>
                    <MdCastForEducation
                      size={35}
                      style={{ color: "#e9f2ff" }}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Education Consultancy</h4>
                  <p className="m-0 text-sm">
                    Strategy consulting, Operations consulting, Financial
                    consulting, Information technology consulting and Human
                    resources consulting.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className={styles.imgBox}>
                    <SlEnergy size={35} style={{ color: "#e9f2ff" }} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Renewable Energy</h4>
                  <p className="m-0 text-sm">
                    Solar Panels, Inverters, Central Inverters, Micro Inverters,
                    Racking Systems, Ground mounts, Ballasted Roof Mounts.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;

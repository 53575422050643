import React from "react";
import { Link } from "react-router-dom";
// Import Img
import img1 from "../../assets/img/common/about-service.jpg";
import { Grid } from "@material-ui/core";
import PageHeading from "../elements/PageHeading";
import Cta from "./Cta";

const HomeTwoAbout = () => {
  return (
    <>
      <section id="service_about">
        <div className="container">
          <PageHeading
            heading={"The story behind the project"}
            text={
              "Businesses not resident in Turkey, find it hard to export products from turkey or operate in the Turkish market. We help and guide businesses with tools and means to help in exportation and consulting services or how to best operate in this market."
            }
          />
          <Grid container spacing={3} style={{ marginTop: 40 }}>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <div className="about_service_img">
                <img src={img1} alt="img-about" />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <div className="about_service_text">
                <div className="heading-left-border" style={{ marginTop: -30 }}>
                  <h4>FRESH TRADİNG DANIŞMANLIK VE TİCARET LİMİTED ŞİRKETİ</h4>
                </div>
                <p>
                  Our warehousing services are known nationwide to be one of the
                  most reliable, safe and affordable, because we take pride in
                  delivering the best of warehousing services, at the most
                  reasonable prices.
                </p>
                <p>
                  We cover the entire supply chain on orders no matter where you
                  are located. So place the order and we handle the logistics at
                  very affordable fees.
                </p>
                <Link
                  to="/about"
                  className="btn btn-theme"
                  style={{ backgroundColor: "#ffffff73" }}
                >
                  Request a quote
                </Link>
              </div>
            </Grid>
          </Grid>
          <Cta />
        </div>
      </section>
    </>
  );
};

export default HomeTwoAbout;

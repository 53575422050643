import React from "react";
import { Grid } from "@material-ui/core";
import PageHeading from "../elements/PageHeading";
import Cta from "./Cta";

const HomeTwoAbout = () => {
  return (
    <>
      <section id="service_about">
        <div className="container">
          <PageHeading
            heading={"Foreign Trade Consultancy"}
            text={
              "FRESH TRADİNG DANIŞMANLIK VE TİCARET LİMİTED ŞİRKETİ also offers service and support on Foreign Trade issues to those companies in any industry and business branch which perform or want to perform importation and exportation operations."
            }
          />
          <Grid container spacing={3} style={{ marginTop: 40 }}>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <div class="entry-content">
                <figure class="wp-block-image size-large is-resized">
                  <img
                    src="https://www.brcplus.com/wp-content/uploads/2021/09/harita-1-1024x462.png"
                    alt=""
                    class="wp-image-396"
                    width="1100"
                    srcset="https://www.brcplus.com/wp-content/uploads/2021/09/harita-1-1024x462.png 1024w, https://www.brcplus.com/wp-content/uploads/2021/09/harita-1-300x135.png 300w, https://www.brcplus.com/wp-content/uploads/2021/09/harita-1-768x347.png 768w, https://www.brcplus.com/wp-content/uploads/2021/09/harita-1-1536x693.png 1536w, https://www.brcplus.com/wp-content/uploads/2021/09/harita-1-2048x924.png 2048w, https://www.brcplus.com/wp-content/uploads/2021/09/harita-1-830x375.png 830w, https://www.brcplus.com/wp-content/uploads/2021/09/harita-1-230x104.png 230w, https://www.brcplus.com/wp-content/uploads/2021/09/harita-1-350x158.png 350w, https://www.brcplus.com/wp-content/uploads/2021/09/harita-1-480x217.png 480w"
                    sizes="(max-width: 1024px) 100vw, 1024px"
                  />
                </figure>
                <p style={{ fontSize: 20, fontWeight: 400 }}>
                  Within the scope of our Foreign Trade Consultancy Services, we
                  produce solutions for our customer companies to succeed by
                  creating effective and efficient policies in international
                  markets.
                </p>
                <p style={{ fontSize: 20, fontWeight: 400 }}>
                  We carry out market research for those companies that want to
                  market their products in foreign markets, and we carry out the
                  entire export process with the customers found.
                </p>
                <p style={{ fontSize: 20, fontWeight: 400 }}>
                  We search the most suitable manufacturers for our customer
                  companies which want to supply products from foreign markets,
                  and we offer these companies turnkey solutions by carrying out
                  the entire import process based on agreements made.
                </p>
                <p style={{ fontSize: 20, fontWeight: 400 }}>
                  <strong>Our Services</strong>
                </p>
                <ol>
                  <li>
                    Establishment of an international sales and marketing office
                  </li>
                  <li>
                    Establishment of an international sales and marketing team
                  </li>

                  <li>
                    Establishment of a system of foreign sales and marketing
                    strategies
                  </li>

                  <li>
                    Provide sales and marketing training sessions to the team
                  </li>

                  <li>
                    Follow up the entire development process of the team and
                    making referrals
                  </li>

                  <li> Follow-up of the team's recruitment processes</li>

                  <li>
                    Overseas office, store, branch warehouse, representative
                    office -and brand promotion activities assistance
                  </li>

                  <li>Organizing foreign fair processes</li>

                  <li>Follow-up of fairs abroad</li>

                  <li> Providing language support at foreign fairs</li>

                  <li>Coordinate and develop research</li>

                  <li>Competitor analysis</li>

                  <li> Environmental/portfolio acquisition assistance</li>

                  <li>Foreign customer search activities</li>

                  <li> Customer pay plans and tracking process</li>

                  <li>Logistics processes after the sale is made</li>

                  <li>Preparing international agreements</li>

                  <li>
                    Provide all the documents needed internationally for the
                    company to achieve its sustainable goals
                  </li>

                  <li>
                    Follow-ups and visits to the fair are done by us, alongside
                    the company's employees
                  </li>

                  <li>
                    Weekly and monthly meetings (There should be goals,
                    analyses, and reports in this meeting)
                  </li>

                  <li>
                    Determination of memberships necessity in institutions and
                    organizations and follow-up of the membership process
                  </li>

                  <li>
                    Providing and closely monitoring training for the company
                  </li>

                  <li>
                    lastly, it will support the company in the processes related
                    to product marketing of the company’s products and its
                    sub-brands
                  </li>
                </ol>
              </div>
            </Grid>
          </Grid>
          <Cta />
        </div>
      </section>
    </>
  );
};

export default HomeTwoAbout;

import React from "react";
// import sections
import About from "../components/About";

const Home = () => {
  return (
    <>
      <About />
    </>
  );
};

export default Home;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: 350,
    alignItems: "center",
    margin: "auto",
  },
  cardImage: {
    width: 350,
    height: 300,
  },
});

const LogisticsCard = (props) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
          <img className={classes.cardImage} src={props.img} alt="Image_Card" />
          <div className="link-icon">
            <a href="#!">
              <i className="fas fa-link"></i>
            </a>
          </div>
        </div>
        <div className="logistics_item_text">
          <h4>
            <span className="bold" style={{ color: "#666666" }}>
              {props.heading}
            </span>
          </h4>
          <p style={{ color: "#666666" }}>{props.para}</p>
        </div>
      </div>
    </>
  );
};

export default LogisticsCard;

import React, { useRef, useState } from "react";
//Import FormInput
import FormInput from "../elements/FormInput";
import Button from "../elements/FormButton";
import emailjs from "@emailjs/browser";
import { CircularProgress } from "@material-ui/core";

// ContactForm Area

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 350,
    marginTop: 30,
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
  formInput: {
    backgroundColor: "black",
  },
}));

const ContactForm = () => {
  const classes = useStyles();
  const serviceId = "service_as0kdsf";
  const templateId = "template_y0olc87";
  const publicKey = "QtYsJuJrXCpa_Xmq5";

  const form = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = (e) => {
    setIsLoading(true);
    e.preventDefault(); // prevents the page from reloading when you hit “Send”

    emailjs.sendForm(serviceId, templateId, form.current, publicKey).then(
      (result) => {
        alert("Form sent successfully.");
        setIsLoading(false);
        window.location.reload();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <>
      <div className={classes.root}>
        <form ref={form} onSubmit={sendEmail}>
          <div className="col-lg-12">
            <FormInput
              tag={"input"}
              type={"text"}
              name={"from_name"}
              classes={"form-control"}
              placeholder={"Name"}
              className={classes.formInput}
            />
          </div>
          <div className="col-lg-12">
            <FormInput
              tag={"input"}
              type={"number"}
              name={"phone"}
              classes={"form-control"}
              placeholder={"Phone number"}
              className={classes.formInput}
            />
          </div>
          <div className="col-lg-12">
            <FormInput
              tag={"input"}
              type={"number"}
              name={"whatsapp"}
              classes={"form-control"}
              placeholder={"WhatsApp number"}
              className={classes.formInput}
            />
          </div>
          <div className="col-lg-12">
            <FormInput
              tag={"input"}
              type={"email"}
              name={"email"}
              classes={"form-control"}
              placeholder={"Email"}
              className={classes.formInput}
            />
          </div>
          <div className="col-lg-12">
            <FormInput
              tag={"input"}
              type={"text"}
              name={"subject"}
              classes={"form-control"}
              placeholder={"Subject"}
              className={classes.formInput}
            />
          </div>
          <div className="col-lg-12">
            <FormInput
              tag={"textarea"}
              type={"textarea"}
              name={"message"}
              classes={"form-control"}
              placeholder={"How can we help?..."}
              className={classes.formInput}
            />
          </div>
          <div className="col-lg-12">
            <div className="contact_form_submit">
              <Button
                color="primary"
                size="small"
                style={{ cursor: "pointer" }}
                tag={"button"}
                val={isLoading ? <CircularProgress size={20} /> : "Send"}
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ContactForm;

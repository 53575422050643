import React from "react";
// Import ContactForm Area
import ContactForm from "../Contact/ContactForm";
// Import ContactInfo Area
import ContactInfo from "../Contact/ContactInfo";
import PageHeading from "../elements/PageHeading";

import { Grid } from "@material-ui/core";

//ContactArea Area
const ContactDetails = () => {
  return (
    <>
      <section id="contact_area_main">
        <div className="container">
          <PageHeading
            heading={"Speak with us, how can we help you?"}
            text={
              "We have custom plans to power your business. Tell us your needs, and we’ll contact you shortly."
            }
          />
          <Grid container spacing={1} style={{ marginTop: 40 }}>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <ContactForm />
            </Grid>
            <Grid item xs={12} sm={12} lg={6} xl={6}>
              <ContactInfo />
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
};

export default ContactDetails;

import React from "react";
import { Grid } from "@material-ui/core";
import PageHeading from "../elements/PageHeading";

const HomeTwoAbout = () => {
  return (
    <>
      <section id="service_about">
        <div className="container">
          <PageHeading heading={"Privacy Policy"} />
          <Grid container spacing={3} style={{ marginTop: 40 }}>
            <Grid item xs={12} sm={12} lg={12} xl={12}>
              <div>
                <p style={{ fontSize: 20, fontWeight: 400 }}>
                  <b>FRESH TRADİNG DANIŞMANLIK VE TİCARET LİMİTED ŞİRKETİ</b> is
                  committed to respecting your right to privacy. Because the
                  information of a company’s facilities and employees is private
                  and sensitive, we know that you may have concerns about
                  sharing such information with us. The’ Privacy Statement ‘
                  includes issues related to the confidentiality of your
                  information as companies that benefit from our support
                  engineering services for our customers, suppliers and staff.
                  <br />
                  <br />
                  <b>I.The Information We Collect.</b>
                  <br />
                  <br />
                  We have access to information about all our services. These
                  consist of information that you voluntarily provide to us and
                  are collected during our activities.
                  <br />
                  <br />
                  <b>II.How We Use Your Information.</b>
                  <br />
                  <br />
                  In order to provide better service for you and meet your
                  needs, this information is stored by our expert staff. We
                  ensure that these processes are managed efficiently by
                  communicating with you in order to identify your needs in the
                  future and provide better service.
                  <br />
                  <br />
                  <b>III. Our Relationships With Third Parties.</b>
                  <br />
                  <br />
                  We may use third parties to provide you with better service
                  and information. Your information will not be shared with
                  anyone other than our company and these third parties with
                  whom we have a business relationship. If we grant third-party
                  contractors access to your information, our company will not
                  authorize them to obtain or use this information for a purpose
                  that is not consistent with this statement. We undertake not
                  to sell or disclose any information you provide to us to third
                  parties without your explicit consent.
                  <br />
                  <br />
                  <b>IV. Security Measures.</b>
                  <br />
                  <br />
                  We want all your information to be as secure as possible to a
                  reasonable extent. Our success in improving our services
                  depends on your willingness to share information about the
                  services we provide to you with our company. We use software
                  that is protected by encryption applications to help ensure
                  the integrity and confidentiality of certain service and
                  project information that you provide to us. We want you to
                  know that only personnel who have access to your documents on
                  paper use them. All information is protected by security
                  measures that prevent physical or digital access by
                  unauthorized users. Although we will make reasonable efforts
                  to protect information from loss, misuse or alteration by
                  third parties, we should note that there is always a risk that
                  an unauthorized party may interfere or someone may disrupt our
                  security systems. <br />
                  <br />
                  <b>V. Changes To Our Privacy Statement.</b>
                  <br />
                  <br />
                  That’s all your information! It will be used as described in
                  the Privacy Statement. However, we reserve the right to change
                  the terms of this’ Privacy Statement ‘ at any time. In any
                  case, if we decide to use this information differently than
                  when it was collected, we will notify companies with an email
                  address. You will be given the opportunity to “confirm” at
                  relevant times for the use or disclosure of your information
                  that you provided to us prior to the change in our statement.
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
};

export default HomeTwoAbout;

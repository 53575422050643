import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import ButtonGroup from "../elements/ButtonGroup";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Fresh Trading Consulting",
    paragraph:
      "Fresh trading limited also provides consultancy in foreign trade. Here are a host of services we offer.",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <p style={{ textAlign: "center" }}>
            Fresh trading also offers service and support on Foreign Trade
            issues to those companies in any industry and business branch which
            perform or want to perform importation and exportation operations.
          </p>
          <div className="container-xs">
            <ButtonGroup>
              <ButtonGroup>
                <Link
                  to="/foreign-trade"
                  className="button button-primary button-wide-mobile button-sm"
                >
                  View services
                </Link>
                {/* <Button tag="a" color="primary" wideMobile href="/contact">
                    Request Quote
                  </Button> */}
                <Link
                  to="/contact"
                  className="button button-default button-wide-mobile button-sm"
                >
                  Request Quote
                </Link>
              </ButtonGroup>
            </ButtonGroup>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;

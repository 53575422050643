import React from "react";
// Import Counter
// import Counter from "./Counter";
// About Img
import img1 from "../../../assets/img/home1/about.png";
import { Grid } from "@material-ui/core";
// Counter Data
// const CounterData = [
//   {
//     countStart: 1,
//     countEnd: 16,
//     heading: "COMPANY ESTABLISHED",
//     icon: " fas fa-building",
//   },
//   {
//     countStart: 1,
//     countEnd: 4,
//     heading: "COUNTRIES SERVED",
//     icon: "fas fa-globe",
//   },
//   {
//     countStart: 1,
//     countEnd: 8,
//     heading: "PORTS SERVED",
//     icon: " fas fa-building",
//   },
// ];

const HomeAbout = () => {
  return (
    <>
      <section id="home_about_area">
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={5} xl={5}>
              <div className="about_img">
                <img src={img1} alt="About_Img" />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} lg={7} xl={7}>
              <div className="about_content" style={{ marginBottom: "10rem" }}>
                <h3
                  style={{ marginTop: -20, fontWeight: 900, color: "#e0e7ff" }}
                >
                  All our efforts and investments are geared towards offering
                  better solutions.
                </h3>
                <p>
                  Fresh Trading Limited controls and manages the moving of
                  commercial goods effectively within the supply chain which
                  tends to over-accelerates delivery fulfillment. We are at the
                  forefront of developing innovative supply chain solutions. We
                  offer services such as order processing, packaging,
                  warehousing, inventory management, and so on. We help
                  businesses make the best routing and operational decisions to
                  ensure that the organization makes the most of its fleet and
                  driver resources.
                </p>
                <div className="about_sign_arae">
                  <div className="distaion_area">
                    <h5>Jeff Kenny</h5>
                    <h6>CEO, Fresh Trading Limited</h6>
                  </div>
                  {/* <div className="signature">
                    <img src={img2} alt="About_Img" />
                  </div> */}
                </div>
              </div>
            </Grid>
          </Grid>
          {/* <div className="about_counter_area">
            <div className="row">
              {CounterData.map((data, index) => (
                <Counter
                  countStart={data.countStart}
                  countEnd={data.countEnd}
                  heading={data.heading}
                  icon={data.icon}
                  key={index}
                />
              ))}
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default HomeAbout;

import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Image from "../elements/Image";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
              style={{ lineHeight: 1 }}
            >
              Empower Your Supply Chain{" "}
              <span className="text-color-primary">With Us</span>
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                <b>Fresh trading limited</b> helps businesses and individuals
                export products from Turkey to any part of the World. Our
                digital solutions help you track your exports from order
                placements to delivery. We take over the entire supply chain.
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Link
                    to="/contact"
                    className="button button-primary button-wide-mobile button-sm"
                  >
                    Request Quote
                  </Link>
                  {/* <Button tag="a" color="primary" wideMobile href="/contact">
                    Request Quote
                  </Button> */}
                  <Link
                    to="/contact"
                    className="button button-default button-wide-mobile button-sm"
                  >
                    Contact us
                  </Link>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
            >
              <Image
                className="has-shadow"
                src={require("./../../assets/images/video-placeholder.png")}
                alt="Hero"
                width={700}
                height={400}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
